import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "src/components/layout"
import Head from "src/components/Head"
import LicenseCategory from "../../components/clients/licenseCategory/LicenseCategory"
import "../license.scss"
import { useTranslation } from "react-i18next"
import BRANDS from "../../brands"

const ClientsMain = () => {
  const data = useStaticQuery(graphql`
    query LicensesQueryBy {
      license: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(by/(license|policy))/" } }
        sort: {order: DESC, fields: [frontmatter___date] }
      ) {
        licenses: nodes {
          fields {
            slug
          }
          frontmatter {
            title
            category
            subcategory
          }
          id
        }
      }
      site: site {
        siteMetadata {
          countries {
            by {
              hotLine {
                tel
                title
              }
            }
          }
        }
      }
    }
  `)

  const LICENSES_CATEGORIES = ["policy", "license"]

  const { licenses } = data.license
  const { hotLine } = data.site.siteMetadata.countries.by
  const country = "by"
  const { t, i18n } = useTranslation()

  const licensesCategories = {}

  LICENSES_CATEGORIES.forEach((categoryName) => {
    const licensesList = licenses.filter(
      (license) => license.frontmatter.subcategory === categoryName
    )

    if (licensesList.length > 0) {
      const firstLicenseSlug = licensesList[0].fields.slug

      licensesCategories[categoryName] = {
        id: categoryName,
        title: `licenses.${categoryName}`,
        licenses: licensesList,
        slug: firstLicenseSlug,
      }
    }
  })

  return (
    <Layout country={country} hotLine={hotLine}>
      <Head
        lang={country}
        title={t("head.index.title", { brand: BRANDS.ALLSPORTS.brand })}
        description={t("head.description", { brand: BRANDS.ALLSPORTS.brand })}
        slug="/"
      />
      <div className="license-header">
        <h2>{t("licenses.title")}</h2>
      </div>

      <div className="licenses">
        {Object.values(licensesCategories).map((license) => {
          return (
            <LicenseCategory
              key={license.id}
              title={license.title}
              slug={license.slug}
            />
          )
        })}
      </div>
    </Layout>
  )
}

export default ClientsMain
